<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    toggleCollapseEnable
    :onExport="onExport"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
      @submit.native.prevent
    >
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="投递人："  prop="user">
          <el-input v-model="form.user" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="投递岗位：">
          <el-input
            v-model="form.keyword"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="投递企业：" prop="company">
          <el-input
            v-model="form.company"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="岗位类型：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择 ">
            <el-option
              v-for="item in categoryIdArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="岗位行业：" prop="company_category">-->
<!--          <el-cascader-->
<!--            placeholder="请选择，可搜索"-->
<!--            :value="form.company_category.split(',')"-->
<!--            :options="companyCategoryArr"-->
<!--            @change="(val) => (form.company_category = val.join(','))"-->
<!--            clearable-->
<!--            filterable-->
<!--            :props="{-->
<!--              checkStrictly: true,-->
<!--              label: 'name',-->
<!--              value: 'name',-->
<!--              children: 'children',-->
<!--              expandTrigger: 'hover',-->
<!--            }"-->
<!--          ></el-cascader>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="简历类型：" prop="resume_category">-->
<!--          <el-select v-model="form.resume_category" placeholder="请选择">-->
<!--            <el-option-->
<!--              v-for="item in resumeCategoryArr"-->
<!--              :key="item.id"-->
<!--              :label="item.name"-->
<!--              :value="item.id"-->
<!--            >-->
<!--            </el-option> </el-select-->
<!--        ></el-form-item>-->
<!--      </div>-->
<!--      <div class="filter-item">-->
        <el-form-item label="投递状态：" prop="status">
          <el-select v-model="form.status" placeholder="请选择 ">
            <el-option
              v-for="item in statusArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option> </el-select
        ></el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="投递时间："
          start-prop="create_start_time"
          :start-time.sync="form.create_start_time"
          end-prop="create_end_time"
          :end-time.sync="form.create_end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { getCategories } from '@/modules/employ/api/employ-career-list'
import { exportDeliver } from '@/modules/employ/api/employ-deliver-list'
import { categoryList } from '@/modules/employ/api/employ-career-category-list'
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      categoryIdArr: [],
      companyCategoryArr: [],
      resumeCategoryArr: [],
      statusArr: [
        { name: '全部', id: -1 },
        { name: '未投递', id: 0 },
        { name: '已投递', id: 1 },
        { name: '投递失败', id: 2 },
      ],
    }
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },

    //筛选
    ok() {
      this.uploadFilter(this.form)
    },

    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page }
      exportDeliver(postData).catch((err) => {})
    },
  },
  created() {
    getCategories().then((res) => {
      this.companyCategoryArr = [...res.data]
    })
    categoryList().then((res) => {
      this.categoryIdArr = [{ name: '全部', id: -1 }, ...res.data.list]
    })
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
