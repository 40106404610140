var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('list-layout',{ref:"employCareerListRefs",attrs:{"filter-form":_vm.form,"thead":_vm.thead,"on-fetch":_vm.getList},on:{"command":_vm.onCommand},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var pageData = ref.pageData;
var idList = ref.idList;
return [_c('ListFilter',{attrs:{"filterForm":_vm.form,"page":pageData.page,"uploadFilter":_vm.ok,"ids":idList}})]}},{key:"item_status_text",fn:function(ref){
var row = ref.row;
return [(row._loading)?_c('span',[_vm._v("投递中...")]):_c('list-status-text',{attrs:{"type":row.status_text === '未投递'
            ? 'warning'
            : row.status_text === '投递失败'
            ? 'danger'
            : 'success',"text":row.status_text}})]}},{key:"operation",fn:function(ref){
            var row = ref.row;
return [_c('el-button',{attrs:{"disabled":row.status === 1,"type":"text","size":"small"},on:{"click":function($event){return _vm.onCommand({ command: 'transpondPdf', row: row })}}},[_vm._v(" 代发简历 ")]),_c('el-button',{attrs:{"loading":_vm.downloadingId === row.id,"type":"text","size":"small"},on:{"click":function($event){return _vm.onCommand({ command: 'downloadPdf', row: row })}}},[_vm._v(" 下载简历 ")])]}}])}),_c('Pagination',{attrs:{"total":_vm.pageData.total,"page":_vm.pageData.current_page,"change":_vm.changPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }