<template>
  <div class="page">
    <list-layout
      ref="employCareerListRefs"
      :filter-form="form"
      :thead="thead"
      :on-fetch="getList"
      @command="onCommand"
    >
      <template #filter="{ pageData, idList }">
        <ListFilter
          :filterForm="form"
          :page="pageData.page"
          :uploadFilter="ok"
          :ids="idList"
        />
      </template>
      <template #item_status_text="{ row }">
        <!--        <list-status-text v-if="row._loading" type="info" text="投递中..."></list-status-text>-->
        <span v-if="row._loading">投递中...</span>
        <list-status-text
          v-else
          :type="
            row.status_text === '未投递'
              ? 'warning'
              : row.status_text === '投递失败'
              ? 'danger'
              : 'success'
          "
          :text="row.status_text"
        />
      </template>
      <template #operation="{ row }">
        <el-button
          :disabled="row.status === 1"
          type="text"
          size="small"
          @click="onCommand({ command: 'transpondPdf', row })"
        >
          代发简历
        </el-button>
        <el-button
          :loading="downloadingId === row.id"
          type="text"
          size="small"
          @click="onCommand({ command: 'downloadPdf', row })"
        >
          下载简历
        </el-button>
      </template>
    </list-layout>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
  </div>
</template>

<script>
import {
  getDeliverList,
  resumeDetail,
} from '@/modules/employ/api/employ-deliver-list'
import ListFilter from '@/modules/employ/components/employ-deliver-list/ListFilter.vue'
import ListLayout from '@/base/layout/ListLayout'
import Pagination from '@/base/components/Default/Pagination'
import ListStatusText from '../../../base/components/List/ListStatusText'
import { deliverResumeThirdly } from '../api/employ-deliver-list'

export default {
  components: {
    ListStatusText,
    ListLayout,
    ListFilter,
    Pagination,
  },
  data() {
    return {
      form: {
        user: '', // 用户名称
        career: '', // 岗位名称
        create_start_time: -1,
        create_end_time: -1,
        company: '', // 企业名称
        career_category: '', // 岗位类型名称
        company_category: '', // 行业类型
        resume_category: '', // 简历类型
        status: -1, // 状态：-1 全部，0:未投递，1:已投递，2:投递失败
        page: 1,
        page_size: 20,
      },
      // 弹窗不需要显示操作列，需要重新声明覆盖掉
      thead: [
        { label: '投递人', prop: 'user_nickname', minWidth: 100 },
        { label: '投递岗位', prop: 'career_name', minWidth: 100 },

        { label: '投递企业', prop: 'company_name', minWidth: 100 },

        { label: '岗位类型', prop: 'career_category_name', minWidth: 100 },
        // { label: '岗位行业', prop: 'company_category', minWidth: 100 },

        { label: '投递邮箱', prop: 'company_email', minWidth: 180 },
        {
          label: '投递状态',
          prop: 'status_text',
          type: 'slot',
          minWidth: 100,
        },
        { label: '投递时间', prop: 'create_time', minWidth: 180 },
        {
          label: '操作',
          type: 'operation',
          // width: 230,
          width: 180,
        },
      ],
      pageData: {
        page_size: 15,
        tab: 'normal',
      },
      downloadingId: '',
    }
  },
  methods: {
    // 新增、编辑保存成功后刷新
    onRefresh() {
      this.$refs.employCareerListRefs?.getList({ page: 1 })
    },

    // 筛选回调
    ok(e) {
      this.onRefresh()
    },
    // 拉取数据
    getList(query) {
      return getDeliverList(query)
    },
    changPage(e) {
      this.form.page_size = e.page_size
      const pageData = { ...this.form, ...e }
      this.getList(pageData)
    },
    onCommand({ command, row }) {
      switch (command) {
        case 'transpondPdf':
          row.status = 1 // 看看有没有用
          row._loading = true // 等待中
          deliverResumeThirdly({
            id: row.id,
          })
            .then(({ code, msg }) => {
              if (code) {
                this.$message.success(msg)
                row.status_text = '已投递'
              } else {
                this.$message.error(msg)
                row.status = 2 // 2 是失败哦
                row.status_text = '投递失败'
              }
              row._loading = false // 等待结束
              this.$refs.employCareerListRefs?.getList()
            })
            .catch((err) => {
              console.log(err)
            })
          break
        case 'downloadPdf':
          this.downloadingId = row.id
          resumeDetail({ id: row.id })
            .then((res) => {
              this.downloadingId = ''
            })
            .catch(() => {
              this.downloadingId = ''
            })
          break
        default:
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  position: absolute;
  right: 60px;
  top: 110px;
}
</style>
