import api from '@/base/utils/request'
import fetchFile from '@/base/utils/download'

export const getDeliverList = (data) => {
  return api({
    url: '/admin/employ/deliver/index',
    method: 'post',
    data,
  })
}

export const exportDeliver = (data) => {
  return fetchFile({
    url: '/admin/employ/deliver/export',
    method: 'post',
    data,
    download: true,
  })
}


export const resumeDetail = (data) => {
  return fetchFile({
    url: '/admin/employ/deliver/resumeDetail',
    method: 'post',
    data,
    download: true
  })
}

/**
 * 简历代发
 * @param data
 */
export const deliverResumeThirdly = (data) => {
  return api({url: '/admin/employ/deliver/deliverResume', method: 'post', data})
}